import {useState, useEffect} from 'react';

import {actions} from "modules/home/_redux/scanRedux";
import {useDispatch, useSelector} from "react-redux";
import {Link} from 'react-router-dom';

import BalanceInfoModal from "./BalanceInfoModal";
import ChangePasswordModal from "./ChangePassword";
import FaqModal from "./FaqModal";
import PrintModal from "./PrintModal";
import WithdrawModal from "./WithdrawModal";
import {useAuth} from "../../hooks";
import ActiveLink from '../ActiveLink';
import Faq from "../faq";
import {MenuIcon, User} from '../icons';
import Marquee from "../marquee";
import MobileDropdown from "../mobile-dropdown";
import MobileMenu from '../mobile-menu';
import RenderIf from "../RenderIf";
import ScanInput from "../scan-input";

import './Header.css';

const Header = ({isHome}) => {
    const isUserLoggedIn = useAuth();

    const dispatch = useDispatch();

    const {isScanner, inputValue, time} = useSelector(state => state.scan);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showFaq, setShowFaq] = useState(false);
    const [showBalanceInfo, setShowBalanceInfo] = useState(false);
    const [showWithdraw, setShowWithdraw] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [printData, setPrintData] = useState({});
    const [voucherAmount, setVoucherAmount] = useState(0);

    useEffect(() => {
        const handleBodyClick = (e) => {
            const value = e.key;
            const regex = /^\d+$/;
            if(regex.test(value)) dispatch(actions.setInput(value));
        }

        document.body.addEventListener('keydown', handleBodyClick)

        return () => {
            document.body.removeEventListener('keydown', handleBodyClick)
        }
    }, [])

    useEffect(() => {
        if(inputValue) {
            const interval = setInterval(() => {
                dispatch(actions.addTime(1))
            }, 1000);

            if(time >= 2) {
                clearInterval(interval);
                dispatch(actions.resetScanner())
            }

            if(inputValue.length === 16 && time <= 2) {
                dispatch(actions.setIsScanner(true))
            }

                return () => {
                clearInterval(interval)
            }
        }


    }, [inputValue, time, isScanner])

    return (
        <>
        <header className={`header ${isHome ? 'position-absolute' : ''}`}>
            <div className="header__container h-100 px-2 px-sm-4 py-2 py-lg-2">
                <button
                    onClick={() => setIsMenuOpen(true)}
                    className="header__mobile-menu"
                >
                    <MenuIcon/>
                </button>
                <Link to="/" className="header__logo">
                    <img src="/media/topaz-nard-logo-red.svg" alt="Topaz Nard"/>
                </Link>
                <div className="header__list">
                    <ActiveLink
                        to="/"
                        className="header__list-item"
                        activeClassName="header__list-item--active"
                    >
                        Ana səhifə
                    </ActiveLink>
                    <ActiveLink
                        to="/results"
                        className="header__list-item"
                        activeClassName="header__list-item--active"
                    >
                        Nəticələr
                    </ActiveLink>
                    <ActiveLink
                        to="/sportsmen"
                        className="header__list-item"
                        activeClassName="header__list-item--active"
                    >
                        İdmançılar
                    </ActiveLink>
                    <ActiveLink
                        to="/documents"
                        className="header__list-item"
                        activeClassName="header__list-item--active"
                    >
                        Sənədlər
                    </ActiveLink>
                    <ActiveLink
                        to="/partners"
                        className="header__list-item"
                        activeClassName="header__list-item--active"
                    >
                        Partnyorlar
                    </ActiveLink>
                    <ActiveLink
                        to="/live"
                        className="header__list-item"
                        activeClassName="header__list-item--active"
                    >
                        Live
                    </ActiveLink>
                </div>
                <div className="d-flex align-items-center ms-auto header__buttons-right">
                    <RenderIf condition={isUserLoggedIn}>
                        <ScanInput setShowBalanceInfo={setShowBalanceInfo} setVoucherAmount={setVoucherAmount}
                                   className="ms-auto me-3 d-none d-lg-flex"/>
                    </RenderIf>
                    <Faq className={`me-3 header__faq d-none d-lg-flex`} setShowFaq={setShowFaq}/>
                    {
                        isUserLoggedIn ? (
                            <>
                                <MobileDropdown setShowWithdraw={setShowWithdraw} setShowChangePassword={setShowChangePassword} />
                            </>
                        ) : (
                            <>
                                <Link to="/auth/register" className="header__register-button me-3">Qeydiyyat</Link>
                                <Link to="/auth/login" className="header__login-button"><User/> Daxil ol</Link>
                            </>
                        )
                    }
                </div>

            </div>
            <RenderIf condition={isUserLoggedIn}>
                <div className="px-2 py-2 px-sm-4 d-block d-lg-none">
                    <ScanInput setShowBalanceInfo={setShowBalanceInfo} setVoucherAmount={setVoucherAmount}/>
                </div>
            </RenderIf>
            <Marquee/>
            <MobileMenu open={isMenuOpen} onHide={setIsMenuOpen} setShowFaq={setShowFaq}/>
            <RenderIf condition={showChangePassword}>
                <ChangePasswordModal show={showChangePassword} onHide={setShowChangePassword}/>
            </RenderIf>
            <RenderIf condition={showFaq}>
                <FaqModal show={showFaq} onHide={setShowFaq}/>
            </RenderIf>
            <RenderIf condition={showBalanceInfo}>
                <BalanceInfoModal show={showBalanceInfo} onHide={setShowBalanceInfo} voucherAmount={voucherAmount}/>
            </RenderIf>
            <RenderIf condition={showWithdraw}>
                <WithdrawModal show={showWithdraw} onHide={setShowWithdraw} setShowPrintModal={setShowPrintModal} setPrintData={setPrintData}/>
            </RenderIf>
            <RenderIf condition={showPrintModal}>
                <PrintModal show={showPrintModal} onHide={setShowPrintModal} printData={printData}/>
            </RenderIf>
        </header>

        </>
    );
};

export default Header;
