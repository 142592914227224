import {useEffect, useState} from "react";

import {
    LiveKitRoom,
    useTracks, VideoTrack
} from '@livekit/components-react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {Track} from "livekit-client";
import {v4 as uuid} from "uuid";

import {SERVER_URL, getToken} from "./requests";
import "./Live.css";

const Live = () => {
    const [token, setToken] = useState("");

    const id = uuid();


    useEffect(() => {
        getToken(id)
            .then((res) => {
                setToken(res.data?.data?.rtcToken ?? "")
            })
            .catch(err => {
                console.log(err.response)
            })
    }, [id])


    return (
        <div className="live">
            <div className="live__container">
                <LiveKitRoom
                    token={token}
                    serverUrl={SERVER_URL}
                    connect={true}
                >
                    <SingleTrack/>
                </LiveKitRoom>
            </div>
        </div>
    )
}

const SingleTrack = () => {
    const trackRefs = useTracks([Track.Source.Camera]);

    const handleFullScreen = () => {
        const video = document.querySelector(".lk-participant-media-video");
        video.requestFullscreen()
    }

    return (

        <div className="stream">
            {trackRefs?.[0]
                ? <>
                    <VideoTrack trackRef={trackRefs[0]}/>
                    <button className="lk-fullscreen" onClick={handleFullScreen}><FullscreenIcon/></button>
                </> : null
            }
        </div>
    )
}

export default Live