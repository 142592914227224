import { SwipeableDrawer } from '@mui/material';
import { Close } from 'components/icons';
import { useAuth } from 'hooks';
import {createUseStyles} from 'react-jss'
import { Link } from 'react-router-dom';

import ActiveLink from '../ActiveLink';
import RenderIf from '../RenderIf';

import './MobileMenu.css';

const useStyles = createUseStyles({
  drawer: {
    '& .MuiDrawer-paper': {
      maxWidth: '375px',
      width: '100%',
    },
  },
});
const MobileMenu = ({ open, onHide, setShowFaq }) => {
  const classes = useStyles();

  const isAuth = useAuth();

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => onHide(false)}
      onOpen={() => null}
      className={classes.drawer}
    >
      <div className="mobile-menu__title">
        <Link to="/" className="mobile-menu__logo">
          <img src="/media/topaz-nard-logo-red.svg" alt="1x2" />
        </Link>
        <button
            onClick={() => onHide(false)}
            className="mobile-menu__close-button"
        >
          <Close />
        </button>
      </div>
      <ul className="mobile-menu__list">
        <li>
          <ActiveLink to="/" activeClassName="mobile-menu__active">
            Ana səhifə
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/results" activeClassName="mobile-menu__active">
            Nəticələr
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/sportsmen" activeClassName="mobile-menu__active">
            İdmançılar
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/documents" activeClassName="mobile-menu__active">
            Sənədlər
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/partners" activeClassName="mobile-menu__active">
            Partnyorlar
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/live" activeClassName="mobile-menu__active">
            Live
          </ActiveLink>
        </li>
        <li className="mobile-menu__faq">
          <button onClick={() => {
            setShowFaq(true);
            onHide(false);
          }}>Tez-tez verilən suallar</button>
        </li>
      </ul>
    </SwipeableDrawer>
  );
};

export default MobileMenu;
