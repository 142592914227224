import Marquee from "react-fast-marquee";


const MarqueeComponent = () => {
    return (
        <Marquee className="marquee">
            Bu saytın sınaq versiyasıdır və bəzi funksiyalar tam hazır deyil. Xidmətlə bağlı hər hansı problem
            yaşadığınız təqdirdə, 926 qaynar xətti ilə əlaqə saxlayaraq bizə məlumat verin.
        </Marquee>
    )
}

export default MarqueeComponent