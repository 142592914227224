import { Dropdown } from 'antd';
import { ArrowDown, User } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/order
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import './MobileDropwdown.css';

import { otp } from '../../modules/auth/_redux/authCrud';
import { actions } from '../../modules/auth/_redux/authRedux';

const MobileDropdown = ({
  className,
  setShowWithdraw,
  setShowChangePassword,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { playerBalance, phoneNumber } = useSelector((state) => state.auth);

  const onClickChangePassword = () => {
    otp(phoneNumber)
      .then(() => setShowChangePassword(true))
      .catch((err) => toast.error(err.response?.data?.message));
  };

  const items = [
    {
      label: <div onClick={() => setShowWithdraw(true)}>Pul çıxartmaq</div>,
      key: '0',
    },
    {
      label: (
        <div onClick={() => navigate('/balance-history')}>
          Oxudulan vauçerlər
        </div>
      ),
      key: '1',
    },
    {
      label: (
        <div onClick={() => navigate('/win-history')}>Uduşlu vauçerlər</div>
      ),
      key: '2',
    },
    // {
    //     label: <div onClick={() => navigate("/bet-history")}>Oyun tarixçəsi</div>,
    //     key: '3',
    // },
    {
      label: <div onClick={onClickChangePassword}>Şifrəni dəyiş</div>,
      key: '4',
    },
    {
      label: (
        <div
          onClick={() => {
            dispatch(actions.logout());
            navigate('/auth/login');
          }}
        >
          Çıxış et
        </div>
      ),
      key: '1',
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      className={className}
    >
      <span
        className="mobile-dropdown__button"
        onClick={(e) => e.preventDefault()}
      >
        <div className="mobile-dropdown">
          {' '}
          {typeof playerBalance === 'number'
            ? playerBalance.toFixed(2)
            : 0} AZN <User /> <ArrowDown />
        </div>
      </span>
    </Dropdown>
  );
};

export default MobileDropdown;
